/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import * as React from 'react';
import { MainLayout } from '../components/MainLayout';
import Hero from '../components/Homepage/Hero';
import Description from '../components/Homepage/Description';
import { SEO } from '../components/SEO';
import routes from '../static/constants/routes';
import WorkshopDetails from '../components/Homepage/WorkshopDetails';
import Gallery from '../components/Offer/Gallery';
import { seoData } from '../static/seoData';

const IndexPage = () => {
  return (
    <MainLayout>
      <Hero />
      <Description />
      <Gallery />
      <WorkshopDetails />
    </MainLayout>
  );
};

export default IndexPage;

export const Head = ({ pageContext }) => {
  const lang = pageContext.language;
  const metaData = seoData.home[lang];

  return (
    <>
      <SEO
        title={metaData.title}
        description={metaData.description}
        pathname={routes.home}
      />
      <link rel="preload" as="image" href="../assets/icons/table.svg" />
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
